import "/styles/seoulmarket.css";
import "/styles/seoulmarket.helper.css";
import "/styles/transition.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/pagination";

import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import dayjs from "dayjs";
import ko from "dayjs/locale/ko";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useMemo, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Slide } from "react-toastify";
import { useLocalStorage, useToggle } from "react-use";
import { v4 as uuidv4 } from "uuid";

import { SERVER_ERROR_ALERT_PROPS, SMALL_ALERT_WH, TITLE_OBJECT } from "/common/Const/Object";
import Device from "/common/Device";
import { allCommonCloseFn } from "/common/Fn";
import { CommonBasicAlert, CommonConfirmBasicAlert } from "/components/common/AlertBox";
import KakaoPostCodePopUp from "/components/common/KakaoPostCodePopUp";
import LoadingBar from "/components/common/LoadingBar";
import { ToastCustomContainer } from "/components/common/ReactToastify";
import { ColorModeContext } from "/contextAPI";
import useModal from "/hooks/useModal";
import { themeFn } from "/styles/theme";

dayjs.locale("ko");

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { showAlert } = useModal();

  const [localMode, setLocalMode] = useLocalStorage("mode");
  const [mode, setMode] = useState(localMode || "light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        setLocalMode(mode === "light" ? "dark" : "light");
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        ...themeFn(mode),
      }),
    [mode],
  );

  const getLayout = Component.getLayout || ((page) => page);

  const [checkUser, checkUserToggle] = useToggle(false);

  const queryClientDefaultOptions = {
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: true,
    onSuccess: ({ header }) =>
      !header?.success
        ? showAlert({
            text: header?.resMsg,
            ...SMALL_ALERT_WH,
          })
        : null,
    onError: () => showAlert({ ...SERVER_ERROR_ALERT_PROPS }),
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            ...queryClientDefaultOptions,
          },
          mutate: {
            ...queryClientDefaultOptions,
            mutationKey: uuidv4(),
          },
        },
      }),
  );

  const checkUserType = () => {
    const isLoginRoute = ["/m/login", "/login"].some((route) => router.pathname === route); // Login 주소 인가

    if (!isLoginRoute) {
      const userTypeCd = JSON.parse(localStorage.getItem("userInfo"))?.userTypeCd;
      const isMobileRoute = router.pathname.startsWith("/m/"); // Mobile 주소 인가?

      if (userTypeCd) {
        const isSeller = JSON.parse(localStorage.getItem("userInfo"))?.userTypeCd === "SL";

        if (isSeller && !isMobileRoute) {
          return router.replace("/m/main");
        }

        if (!isSeller && isMobileRoute) {
          return router.replace("/home");
        }
      } else {
        return router.replace(isMobileRoute ? "/m/login" : "/login");
      }
    }

    return checkUserToggle(true);
  };

  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    allCommonCloseFn();
    checkUserType();
    setScreenSize();

    // window.addEventListener("resize", setScreenSize);
    //
    // return () => window.removeEventListener("resize", setScreenSize);
  });

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // const mode = JSON.parse(localStorage.getItem("mode"));

    // if (mode === null || mode === undefined) {
    //   const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
    //     ? "dark"
    //     : "light";
    //
    //   setMode(prefersDarkMode);
    //   setLocalMode(prefersDarkMode);
    // }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Head>
            <title>
              우리시장 빠른배송{" "}
              {TITLE_OBJECT[router.pathname] ? ` - ${TITLE_OBJECT[router.pathname]}` : ""}
            </title>
            <meta
              name="viewport"
              content="initial-scale-1.0, maximum-scale=1.0, width=device-width, user-scalable=no"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
              rel="stylesheet"
            />
          </Head>

          <ColorModeContext.Provider value={colorMode}>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <LoadingBar />
                <CommonBasicAlert />
                <CommonConfirmBasicAlert />
                <KakaoPostCodePopUp />
                <Device>
                  {(props) => (
                    <>
                      {getLayout(
                        checkUser ? (
                          <Component
                            {...pageProps}
                            device={props}
                          />
                        ) : (
                          <></>
                        ),
                      )}
                    </>
                  )}
                </Device>
                <div id="un_modalRoot" />

                <ToastCustomContainer transition={Slide} />
              </ThemeProvider>
            </MuiThemeProvider>
          </ColorModeContext.Provider>

          <ReactQueryDevtools
            initialIsOpen={false}
            position={"bottom-right"}
          />
        </Hydrate>
      </QueryClientProvider>

      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-R2TLQ0C2VN"
      />

      <Script
        id="google-analytics"
        strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag("js", new Date());

          gtag("config", "G-R2TLQ0C2VN");`}
      </Script>
    </>
  );
}

export default MyApp;

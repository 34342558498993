import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { observer } from "mobx-react";

import { FLEX_CENTER_CSS } from "/common/Const/Css";
import { useDefaultStyles } from "/common/MakeStyle";
import commonStore from "/mobx/store/commonStore";

const LoadingBar = () => {
  const isOpen = commonStore.loading.isOpen;
  const size = commonStore.loading.size;
  const text = commonStore.loading.text;

  const classes = useStyles({ isOpen, text });
  const defaultClasses = useDefaultStyles();

  if (!isOpen) {
    return <></>;
  }

  return (
    <Box className={classes.loadingBg}>
      <Box className={classNames(defaultClasses.fJCcAIc, defaultClasses.wh)}>
        <CircularProgress
          color="primary"
          size={size || 85}
        />
        <Box className={classes.loadingText}>{text || "Loading..."}</Box>
      </Box>
    </Box>
  );
};

export default observer(LoadingBar);

const useStyles = makeStyles((theme) => {
  const commonStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    ...FLEX_CENTER_CSS,
  };

  return {
    loadingBg: {
      ...commonStyles,
      zIndex: theme.zIndex.infinite * 10,
    },
    loadingText: {
      ...commonStyles,
      color: theme.palette.primary.main,
    },
  };
});

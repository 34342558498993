import { createTheme } from "@material-ui/core";

export const themeFn = (mode) => {
  const isLightMode = mode !== "dark";

  return {
    palette: {
      mode,
      type: mode,
      common: {
        black: "#000",
        white: "#fff",
      },
      original: {
        main: "#1976d2",
        light: "#03a9f4",
        dark: "#01579b",
        contrastText: "#fff",
      },
      primary: {
        main: "#7055ff",
        light: "#8d76ff",
        deepLight: "#c6bbff",
        dark: "#443299",
        deepDark: "#171133",
        contrastText: "#fff",
      },
      secondary: {
        main: "#15eeb4",
        light: "#63F4CD",
        dark: "#0e9f78",
        contrastText: "#fff",
      },
      error: {
        main: "#ff0e5c",
        light: "#ff5e92",
        dark: "#aa093d",
        contrastText: "#fff",
      },
      text: {
        primary: isLightMode ? "#222" : "#fff",
        secondary: isLightMode ? "#666" : "rgba(255, 255, 255, 0.7)",
        tertiary: isLightMode ? "#888" : "rgba(255, 255, 255, 0.5)",
        disabled: isLightMode ? "#f5f5f5" : "rgba(255, 255, 255, 0.4)",
      },
      background: {
        paper: isLightMode ? "#fff" : "#424242",
        default: isLightMode ? "#f5f5f5" : "#303030",
      },
    },
    layout: {
      pcMenu: "240px",
      pcHeaderHeight: "96px",
      pcFooterHeight: "80px",
      pcPageLeftAreaWidth: "1000px",
      pcPageRightContentsWidth: "400px",
      pcDetailInfoWidth: "560px",
    },
    typography: {
      fontWeightSemiBold: 600,
      fontWeightExtraBold: 900,
    },
    zIndex: {
      qrReader: 50,
      mobileTop: 99,
      pcMenu: 9999,
      pcHeader: 9999,
      pcDetailModal: 10000,
      mobileMenu: 9999,
      mobileBottomMenu: 5000,
      reactSelect: 3,
      infinite: 99999,
    },
    shadow: [`0 0 12px 0 rgba(0, 0, 0, 0.1)`, `0 4px 12px 0 rgba(0, 0, 0, 0.1)`],
  };
};

const theme = createTheme({ ...themeFn() });
export default theme;

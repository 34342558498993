import { Box, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import Image from "next/image";
import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { FiAlertCircle } from "react-icons/fi";
import { useEvent, useToggle } from "react-use";

import { FLEX_CENTER_CSS, W_100_CSS, WH_100_CSS } from "/common/Const/Css";
import { pxFn, pxToRemConvertFn } from "/common/Fn";
import { commonImagePropsFn } from "/common/Image";
import { MainColorButton } from "/components/common/Button/MainButton";
import { PrimarySubBtn } from "/components/common/Button/SubButton";
import useGetMobileMainNotice from "/hooks/api/useQuery/notice/useGetMobileMainNotice";
import useModal from "/hooks/useModal";
import commonStore from "/mobx/store/commonStore";

/**
 * Basic Alert Component
 * @param width
 * @param height
 * @param title
 * @param text
 * @param handleClick
 * @param restProps
 * @returns {JSX.Element}
 *
 */
const Alert = ({ width, height, title = "알림", text, handleClick, ...restProps }) => {
  const classes = useStyles({ width, height, restProps });

  return (
    <div className={classes.alertBg}>
      <div className={classes.alertBox}>
        <div className={classes.alertHeader}>
          <FiAlertCircle
            size={pxFn(18)}
            style={{ marginRight: pxFn(4) }}
          />
          {title}
        </div>
        <div className={classes.content}>{text}</div>
        <div className={classes.alertButton}>
          <MainColorButton
            text={"확인"}
            width={pxFn(57)}
            height={pxFn(36)}
            fontSize={pxToRemConvertFn(14)}
            handleClick={handleClick}
            data-cy={"alertY"}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * useModal 상태 값에 따라서 나올 공통 Alert Components
 * @type {(function(): *)|*}
 */
export const CommonBasicAlert = observer(() => {
  const theme = useTheme();
  const { hideAlert } = useModal();

  const handleClick = () => {
    if (commonStore.alert.props?.onClick) {
      commonStore.alert.props?.onClick();
    }
    hideAlert();
  };

  const onKeyDown = useCallback((e) => {
    if (e?.key === "Enter" && commonStore.alert.props?.isOpen) {
      e?.preventDefault();
      handleClick();
    }

    if (e?.key === "Escape" && commonStore.alert.props?.isOpen) {
      e?.preventDefault();
      hideAlert();
    }
  }, []);

  useEvent("keydown", onKeyDown);

  if (commonStore.alert.props?.isOpen) {
    const title = commonStore.alert.props?.title || "";
    const text = commonStore.alert.props?.text || "";
    return (
      <Alert
        width={commonStore.alert.props?.width}
        height={commonStore.alert.props?.height}
        title={title}
        text={text}
        handleClick={handleClick}
        zIndex={theme.zIndex.infinite * theme.zIndex.infinite}
      />
    );
  }

  return <></>;
});

/**
 * Basic Confirm Component
 * @param width
 * @param height
 * @param title
 * @param text
 * @param onClickY
 * @param onClickN
 * @param restProps
 * @returns {JSX.Element}
 *
 */
const ConfirmAlert = ({
  width,
  height,
  title = "알림",
  text,
  onClickY,
  onClickN,
  ...restProps
}) => {
  const classes = useStyles({ width, height, restProps });

  return (
    <div className={classes.alertBg}>
      <div className={classes.alertBox}>
        <div className={classes.alertHeader}>
          {/*<Image*/}
          {/*  alt={""}*/}
          {/*  {...commonImagePropsFn.alert(16, 16)}*/}
          {/*/>*/}
          <FiAlertCircle
            size={pxFn(18)}
            style={{ marginRight: pxFn(4) }}
          />
          {title}
        </div>
        <div className={classes.content}>{text}</div>
        <div className={classes.alertButton}>
          <PrimarySubBtn
            text={"예"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            margin={`0 ${pxFn(8)} 0 0`}
            handleClick={onClickY}
            data-cy={"confirmY"}
          />
          <MainColorButton
            text={"아니요"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            handleClick={onClickN}
            data-cy={"confirmN"}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * useModal 상태 값에 따라서 나올 공통 Confirm Components
 * @type {(function(): *)|*}
 */
export const CommonConfirmBasicAlert = observer(() => {
  const theme = useTheme();
  const { hideConfirm } = useModal();

  const onClickY = () => {
    commonStore.confirm.props.onClickY();
    hideConfirm();
  };

  const onClickN = () => {
    commonStore.confirm.props.onClickN();
    hideConfirm();
  };

  const onKeyDown = useCallback(
    (e) => {
      if (e?.key === "Enter" && commonStore.confirm.props?.isOpen) {
        e?.preventDefault();
        onClickY();
      }

      if (e?.key === "Escape" && commonStore.confirm.props?.isOpen) {
        e?.preventDefault();
        // e?.stopImmediatePropagation();
        onClickN();
      }
    },
    [commonStore.confirm.props?.isOpen],
  );

  useEvent("keydown", onKeyDown);

  if (commonStore.confirm.props?.isOpen) {
    const width = commonStore.confirm.props?.width;
    const height = commonStore.confirm.props?.height;
    const title = commonStore.confirm.props?.title || "";
    const text = commonStore.confirm.props?.text || "";

    return (
      <ConfirmAlert
        {...{
          width,
          height,
          title,
          text,
          onClickY,
          onClickN,
          zIndex: theme.zIndex.infinite * theme.zIndex.infinite,
        }}
      />
    );
  }

  return <></>;
});

/**
 *
 * @param title
 * @param children
 * @param onClickY
 * @param onClickN
 * @param width
 * @param height
 * @param restProps
 * @returns {JSX.Element}
 *
 */
export const InputConfirmBasicAlert = ({
  title,
  children,
  onClickY = () => {},
  onClickN = () => {},
  width = pxFn(400),
  height,
  ...restProps
}) => {
  const classes = useStyles({ width, height, restProps });

  return (
    <div className={classes.alertBg}>
      <div className={classes.alertBox}>
        <div className={classes.alertHeader}>
          <FiAlertCircle
            size={pxFn(18)}
            style={{ marginRight: pxFn(4) }}
          />
          <Box px={0.5}>{title || "알람"}</Box>
        </div>
        <div className={classes.content}>{children}</div>
        <div className={classes.alertButton}>
          <PrimarySubBtn
            text={"취소"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            margin={`0 ${pxFn(8)} 0 0`}
            handleClick={onClickN}
            data-cy={"inputConfirmN"}
          />
          <MainColorButton
            text={"확인"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            handleClick={onClickY}
            data-cy={"inputConfirmY"}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * - for pc right side modal common input modal
 * - path: /components/common/AlertBox
 * @param title - 제목
 * @param children
 * @param onClickY - yes btn fn
 * @param onClickN - no btn fn
 * @param width
 * @param height
 * @returns {JSX.Element}
 *
 */
export const PcDetailInfoInputConfirmBasicAlert = ({
  title,
  children,
  onClickY = () => {},
  onClickN = () => {},
  width = pxFn(400),
  height,
}) => {
  const classes = useStyles({ width, height });

  const onKeyDown = useCallback((e) => {
    if (e?.key === "Enter") {
      e?.preventDefault();
      onClickY();
    }

    if (e?.key === "Escape") {
      e?.preventDefault();
      e?.stopImmediatePropagation();
      onClickN();
    }
  }, []);

  useEvent("keydown", onKeyDown, window, { capture: true });

  return (
    <div className={classes.pcDetailInfoAlertBg}>
      <div className={classes.alertBox}>
        <div className={classes.alertHeader}>
          <FiAlertCircle
            size={pxFn(18)}
            style={{ marginRight: pxFn(4) }}
          />
          <Box px={0.5}>{title || "알람"}</Box>
        </div>
        <div className={classes.content}>{children}</div>
        <div className={classes.alertButton}>
          <PrimarySubBtn
            text={"취소"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            margin={`0 ${pxFn(8)} 0 0`}
            handleClick={onClickN}
            data-cy={"inputConfirmN"}
          />
          <MainColorButton
            text={"확인"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            handleClick={onClickY}
            data-cy={"inputConfirmY"}
          />
        </div>
      </div>
    </div>
  );
};

export const MobileInputConfirmBasicAlert = ({
  title,
  children,
  onClickY = () => {},
  onClickN = () => {},
  width = `calc(100% - ${pxFn(40)})`,
  height,
}) => {
  const classes = useStyles({ width, height });

  return (
    <div className={classes.alertBg}>
      <div className={classes.mobileAlertBox}>
        <div className={classes.alertHeader}>
          <Image
            alt={""}
            {...commonImagePropsFn.alert(20, 20)}
          />
          <Box px={0.5}>{title || "알람"}</Box>
        </div>
        <div className={classes.content}>{children}</div>
        <div className={classes.alertButton}>
          <PrimarySubBtn
            text={"취소"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            margin={`0 ${pxFn(8)} 0 0`}
            handleClick={onClickN}
            data-cy={"inputConfirmN"}
          />
          <MainColorButton
            text={"확인"}
            width={pxFn(64)}
            height={pxFn(40)}
            fontSize={pxToRemConvertFn(14)}
            handleClick={onClickY}
            data-cy={"inputConfirmY"}
          />
        </div>
      </div>
    </div>
  );
};

export const MobileNoticeAlert = () => {
  const [cookies, setCookies] = useCookies(["MODAL_EXPIRES"]);
  const [notice, noticeToggle] = useToggle(!cookies["MODAL_EXPIRES"]);

  const { data: mobileMainNoticeData } = useGetMobileMainNotice();
  const mainPopUpNotice = useMemo(
    () => mobileMainNoticeData?.body?.noticeInfoDTO || null,
    [mobileMainNoticeData],
  );

  const onClickN = () => {
    if (!cookies) return;

    const expires = dayjs(dayjs().format("YYYY-MM-DD")).add(1, "day").toDate();

    setCookies("MODAL_EXPIRES", true, { path: "/", expires });

    noticeToggle(false);
  };

  const classes = useStyles({
    width: `calc(100% - ${pxFn(48)})`,
    height: `calc(100% - ${pxFn(112)})`,
  });

  if (mainPopUpNotice?.noticeId > 0 && notice && !cookies["MODAL_EXPIRES"]) {
    return (
      <div
        className={classes.alertBg}
        data-cy={"mobileNoticeAlert"}>
        <div className={classes.mobileNoticeAlertContainer}>
          <div className={"header"}>
            <div className={"title"}>{mainPopUpNotice?.title || "공지"}</div>
            <div className={"date"}>
              {dayjs(mainPopUpNotice?.regDt)?.format("YYYY.MM.DD") || "-"}
            </div>
          </div>

          <div
            className={"contents"}
            dangerouslySetInnerHTML={{
              __html: mainPopUpNotice?.contents?.replaceAll("\n", "<br/>") || "",
            }}></div>

          <div className={"footer"}>
            <PrimarySubBtn
              text={"오늘 더 안보기"}
              width={"100%"}
              height={pxFn(52)}
              fontSize={pxToRemConvertFn(16)}
              margin={`0 ${pxFn(8)} 0 0`}
              handleClick={onClickN}
              data-cy={"mobileAlertNBtn"}
            />
            <MainColorButton
              text={"확인"}
              width={"100%"}
              height={pxFn(52)}
              fontSize={pxToRemConvertFn(16)}
              handleClick={() => {
                const expires = new Date();
                expires.setDate(expires.getDate() + 1);
                noticeToggle(false);
              }}
              data-cy={"mobileAlertYBtn"}
            />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => {
  const isLightMode = theme.palette.mode === "light";

  const commonAlertBgStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.infinite,
    backgroundColor: "rgba(0,0,0,0.7)",
  };

  const commonAlertBoxStyle = {
    position: "absolute",
    padding: pxFn(16),
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: pxFn(12),
    border: isLightMode ? "none" : `${pxFn(2)} solid ${theme.palette.divider}`,
    boxShadow: theme.shadow[0],
  };

  return {
    alertBg: ({ restProps }) => ({
      ...commonAlertBgStyle,
      zIndex: restProps?.zIndex || commonAlertBgStyle?.zIndex,
    }),

    pcDetailInfoAlertBg: {
      ...commonAlertBgStyle,
      left: `calc(-100vw + ${theme.layout.pcDetailInfoWidth})`,
    },

    alertBox: {
      top: (props) =>
        props.height ? `calc(50% - ${props.height}  / 2)` : `calc(50% - ${pxFn(120)})`,
      left: (props) =>
        props.width ? `calc(50% - ${props.width}  / 2)` : `calc(50% - ${pxFn(200)})`,
      width: (props) => (props?.width ? props?.width : `calc(100% - ${pxFn(40)})`),
      height: (props) => (props.height ? props.height : pxFn(240)),
      ...commonAlertBoxStyle,
    },

    mobileAlertBox: {
      top: (props) =>
        props.height ? `calc(50% - ${props.height}  / 2)` : `calc(50% - ${pxFn(240)})`,
      left: (props) => (props.width ? `calc(50% - ${props.width}  / 2)` : pxFn(20)),
      width: (props) => (props?.width ? props?.width : `calc(100% - ${pxFn(40)})`),
      height: (props) => (props?.height ? props?.height : pxFn(240)),
      ...commonAlertBoxStyle,
    },

    mobileNoticeAlertContainer: {
      ...commonAlertBoxStyle,

      padding: 0,
      top: pxFn(24),
      left: pxFn(24),
      width: ({ width }) => width,
      height: ({ height }) => height,

      "& .header": {
        padding: `0 ${pxFn(24)}`,
        height: pxFn(56),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.text.primary,
        borderBottom: `${pxFn(1)} solid ${theme.palette.divider}`,

        "& .title": {
          color: theme.palette.text.primary,
          fontSize: pxToRemConvertFn(14),
          fontWeight: theme.typography.fontWeightBold,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        "& .date": {
          color: theme.palette.text.secondary,
          fontSize: pxToRemConvertFn(12),
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      "& .contents": {
        padding: `${pxFn(16)} ${pxFn(24)}`,
        ...W_100_CSS,
        height: `calc(100% - ${pxFn(56)} - ${pxFn(80)})`,
        fontSize: pxToRemConvertFn(14),
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: 1.43,
        overflowY: "scroll",
        wordBreak: "keep-all",
      },
      "& .footer": {
        boxSizing: "border-box",
        padding: pxFn(16),
        paddingTop: pxFn(12),
        height: pxFn(80),
        display: "flex",
        boxShadow: theme.shadow[0],
      },
    },

    alertHeader: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
      fontSize: pxToRemConvertFn(16),
      fontWeight: theme.typography.fontWeightMedium,

      "& > span": {
        marginRight: `${pxFn(4)} !important`,
      },
    },

    content: {
      padding: `0 ${pxFn(8)}`,
      ...WH_100_CSS,
      ...FLEX_CENTER_CSS,
      color: theme.palette.text.primary,
      fontSize: pxToRemConvertFn(16),
      fontWeight: theme.typography.fontWeightMedium,
      textAlign: "center",
      wordBreak: "keep-all",
      lineHeight: pxFn(24),
    },

    alertButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
  };
});

import { pxFn } from "/common/Fn";

/**
 * - ITF Type CD 모음
 * - pat: /common/Const/Object
 * @type {{TF: string, AS: string, HJ: string, HP: string, SL: string, GB: string, QN: string, PO: string}}
 */
export const ITF_TYPE_CD_OBJ = {
  HP: "당일배송",
  SL: "서울당일배송",
  TF: "새벽배송",
  AS: "근거리배송",
  GB: "예약퀵",
  QN: "즉시퀵",
  PO: "CJ대한통운",
  HJ: "한진",
};

/**
 * - Cypress Kakao Example Data
 * - path: /common/Const/Object
 * @type {{autoJibunAddressEnglish: string, bname1English: string, autoRoadAddressEnglish: string, bname2English: string, jibunAddressEnglish: string, zonecode: string, roadAddressEnglish: string, bname1: string, bname2: string, sido: string, sidoEnglish: string, roadAddress: string, roadnameEnglish: string, postcode1: string, bcode: string, postcode2: string, postcodeSeq: string, hname: string, address: string, sigungu: string, addressType: string, query: string, noSelected: string, postcode: string, buildingCode: string, jibunAddress: string, sigunguCode: string, userLanguageType: string, userSelectedType: string, addressEnglish: string, buildingName: string, bname: string, bnameEnglish: string, autoRoadAddress: string, fullAddress: string, sigunguEnglish: string, roadnameCode: string, apartment: string, autoJibunAddress: string, roadname: string}}
 */
export const CYPRESS_KAKAO_SAMPLE_DATA = {
  fullAddress: "서울 강남구 가로수길 5 (신사동)",
  postcode: "",
  postcode1: "",
  postcode2: "",
  postcodeSeq: "",
  zonecode: "06035",
  address: "서울 강남구 가로수길 5",
  addressEnglish: "5, Garosu-gil, Gangnam-gu, Seoul, Korea",
  addressType: "R",
  bcode: "1168010700",
  bname: "신사동",
  bnameEnglish: "Sinsa-dong",
  bname1: "",
  bname1English: "",
  bname2: "신사동",
  bname2English: "Sinsa-dong",
  sido: "서울",
  sidoEnglish: "Seoul",
  sigungu: "강남구",
  sigunguEnglish: "Gangnam-gu",
  sigunguCode: "11680",
  userLanguageType: "K",
  query: "강남",
  buildingName: "",
  buildingCode: "1168010700105370005011918",
  apartment: "N",
  jibunAddress: "",
  jibunAddressEnglish: "",
  roadAddress: "서울 강남구 가로수길 5",
  roadAddressEnglish: "5, Garosu-gil, Gangnam-gu, Seoul, Korea",
  autoRoadAddress: "",
  autoRoadAddressEnglish: "",
  autoJibunAddress: "서울 강남구 신사동 537-5",
  autoJibunAddressEnglish: "537-5, Sinsa-dong, Gangnam-gu, Seoul, Korea",
  userSelectedType: "R",
  noSelected: "N",
  hname: "",
  roadnameCode: "4858362",
  roadname: "가로수길",
  roadnameEnglish: "Garosu-gil",
};

/**
 * - Tab Name 설정을 위한 Obj
 * - path: /common/Const/Object
 * @type {{"/addStore": string, "/deliveryList": string, "/login": string, "/location": string, "/home": string, "/bulkDeliveryRegister": string, "/notice": string, "/settlement": string, "/nearDistanceDelivery": string, "/quick": string, "/incoming": string}}
 */
export const TITLE_OBJECT = {
  "/home": "홈",
  "/login": "로그인",
  "/addStore": "점포",
  "/deliveryList": "배송현황",
  "/quick": "퀵",
  "/notice": "공지사항",
  "/bulkDeliveryRegister": "대용량 배송 등록",
  "/settlement": "정산",
  "/incoming": "입하",
  "/location": "로케이션",
  "/nearDistanceDelivery": "근거리배송",
};

export const ORDER_TYPE_CD_CONVERTER = {
  PC: "택배",
  DY: "당일배송",
  DW: "새벽배송",
  NQ: "즉시퀵",
  BQ: "예약퀵",
  SD: "근거리배송",
};

export const DELIVERY_STATS_CD = {
  READY: "배송 진행중이 아닙니다",
  RECEIVED: "배송출발전",
  LV1: "배송준비중",
  LV2: "상품인수중",
  LV3: "상품이동중",
  LV4: "배송지도착",
  LV5: "배송출발",
  LV6: "배송완료",
  CANCEL: "배송취소",
  CANCELED: "배송취소",
};

export const SHIPPING_DELIVERY_STEP = {
  LV1: 0,
  LV2: 1,
  LV3: 2,
  LV4: 3,
  LV5: 4,
  LV6: 4,
  READY: 0,
};

export const QUICK_PROCESS_STEP = {
  DO0010: 1,
  DO0031: 2,
  DO0040: 3,
};

export const RESERVATION_QUICK_PROCESS_STEP = {
  DO0010: 1,
  DO0030: 2,
  DO0040: 3,
};

export const NEAR_DISTANCE_DELIVERY_PROCESS_STEP = {
  DO0010: 1,
  DO0030: 2,
  DO0040: 3,
};

export const DELIVERY_PROCESS_STEP = {
  DO0010: 1,
  DO0020: 2,
  DO0030: 3,
  DO0035: 3,
  DO0040: 3,
};

/**
 * - Small Alert width, height
 * - path: /common/Const/Object
 * @type {{width: string, height: string}}
 */
export const SMALL_ALERT_WH = {
  width: pxFn(350),
  height: pxFn(200),
};

/**
 * - onError showAlert props
 * - path: /common/Const/Object
 * @type {{width: string, text: string, height: string}}
 */
export const SERVER_ERROR_ALERT_PROPS = {
  text: "Server Error.",
  ...SMALL_ALERT_WH,
};

/**
 * - 정산 공통 객체
 * - path: /common/Const/Object
 * @type {{money: string, paymentYn: string}}
 */
const COMMON_DEFAULT_VALUE = {
  paymentYn: "N",
  money: "",
};

export const CARD_DEFAULT_VALUE = {
  ...COMMON_DEFAULT_VALUE,
  code: "CD",
  company: "",
  confirmNum: "",
};

export const ACCOUNT_COMPANY_DEFAULT_VALUE = {
  label: "은행명 없음",
  value: "",
};
export const ACCOUNT_DEFAULT_VALUE = {
  ...COMMON_DEFAULT_VALUE,
  code: "AC",
  company: ACCOUNT_COMPANY_DEFAULT_VALUE,
};

export const MONEY_DEFAULT_VALUE = {
  ...COMMON_DEFAULT_VALUE,
  code: "CS",
};

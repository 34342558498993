/**
 * - DEFAULT TABLE PAGE SIZE
 * - path: common/Const/Rule/index.js
 * @type {number}
 */
export const DEFAULT_TABLE_PAGE_SIZE = 15;

/**
 * - 정규식 표현 모음
 * - path: common/Const/Rule/index.js
 * @type {{TEXT_FIELD_CHECK: RegExp, PHONE_NUM: RegExp, PASSWORD: RegExp, NUM: RegExp, SAFE_PHONE_NUM_CHECK: RegExp, BUSINESS_NUM_REPLACE: RegExp, SAFE_PHONE_NUM: RegExp, CARD_NUM_REPLACE: RegExp, ID: RegExp, EMAIL: RegExp, PHONE_NUM_CHECK: RegExp, BUSINESS_NUM: RegExp}}
 */
export const REGULAR_EXPRESSION = {
  ID: /^[0-9a-zA-Z]{1,14}$/,
  PASSWORD: /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/,
  EMAIL: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
  NUM: /[^0-9]/g,
  TEXT_FIELD_CHECK: /^[a-zA-Z0-9ㄱ-힣{}\[\]\/?.,;:|)*~`!^\-_+<>@#$%&\\=('"\s]*$/,
  PHONE_NUM: /^(\d{2,3})(\d{3,4})(\d{4})$/,
  PHONE_NUM_CHECK: /^(\d{2,4})-(\d{3,4})-(\d{4})$/,
  SAFE_PHONE_NUM: /^(\d{4})(\d{4})(\d{4})$/,
  SAFE_PHONE_NUM_CHECK: /^(\d{4})-(\d{4})-(\d{4})$/,
  BUSINESS_NUM: /^\d{3}-\d{2}-\d{5}$/,
  BUSINESS_NUM_REPLACE: /^(\d{3})(\d{2})(\d{5})$/,
  CARD_NUM_REPLACE: /^(\d{4})(\d{4})(\d{4})(\d{4})$/,
};

export const REQUIRED = {
  required: {
    value: true,
    message: "필수 값을 입력하세요.",
  },
};

/**
 * - Form Rules 모음
 * - path: common/Const/Rule/index.js
 * @type {{businessNum: (function(boolean=): {pattern: {message: string, value: RegExp}, required?: {message: string, value: boolean}}), password: (function(boolean=): {pattern: {message: string, value: RegExp}, required?: {message: string, value: boolean}, maxLength: {message: string, value: number}}), name: (function(boolean=): {pattern: {message: string, value: RegExp}, required?: {message: string, value: boolean}}), phoneNum: (function(boolean=): {pattern: {message: string, value: RegExp}, required?: {message: string, value: boolean}}), id: (function(boolean=): {pattern: {message: string, value: RegExp}, required?: {message: string, value: boolean}, maxLength: {message: string, value: number}}), email: (function(boolean=): {pattern: {message: string, value: RegExp}, required?: {message: string, value: boolean}})}}
 */
export const FORM_RULES = {
  id: (required = false) => ({
    ...(required ? REQUIRED : {}),
    pattern: {
      value: REGULAR_EXPRESSION.ID,
      message: "*영어 대문자, 소문자, 숫자 혼합 최대 15자",
    },
    maxLength: {
      value: 15,
      message: "최대 길이를 초과했습니다.",
    },
  }),
  password: (required = false) => ({
    ...(required ? REQUIRED : {}),
    pattern: {
      value: REGULAR_EXPRESSION.PASSWORD,
      message: "*영어, 숫자, 특수문자 혼합 8~15자",
    },
    maxLength: {
      value: 15,
      message: "최대 길이를 초과했습니다.",
    },
  }),
  name: (required = false) => ({
    ...(required ? REQUIRED : {}),
    pattern: {
      value: REGULAR_EXPRESSION.TEXT_FIELD_CHECK,
      message: `올바르지 않은 문자입니다.`,
    },
  }),
  phoneNum: (required = false) => ({
    ...(required ? REQUIRED : {}),
    pattern: {
      value: REGULAR_EXPRESSION.PHONE_NUM_CHECK,
      message: `올바른 번호 형식이 아닙니다.`,
    },
  }),
  businessNum: (required = false) => ({
    ...(required ? REQUIRED : {}),
    pattern: {
      value: REGULAR_EXPRESSION.BUSINESS_NUM,
      message: "올바른 사업자등록번호 형태가 아닙니다.",
    },
  }),
  email: (required = false) => ({
    ...(required ? REQUIRED : {}),
    pattern: {
      value: REGULAR_EXPRESSION.EMAIL,
      message: "올바른 이메일 형태가 아닙니다.",
    },
  }),
};

import { useQuery } from "react-query";

import { commonOnSuccess, getAPIData } from "/common/Fn";

const useGetMobileMainNotice = (queryData = {}, options = {}) =>
  useQuery(
    [`/notice/search-main-notice`],
    async () => getAPIData(`/notice/search-main-notice`, queryData),
    {
      onSuccess: ({ header }) => commonOnSuccess(header),
      ...options,
    },
  );

export default useGetMobileMainNotice;

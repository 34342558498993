import commonStore from "/mobx/store/commonStore";

/**
 * - 공통 Alert & Confirm 모달을 제어하기 위한 custom hook
 * - mobx store 의 commonStore.alert 및 commonStore.confirm 를 제어하기 위한 custom hook
 * @example
 * // showAlert: 공통 Alert show 함수
 * // hideAlert: 공통 Alert hide 함수
 * // showConfirm: 공통 Confirm show 함수
 * // hideConfirm: 공통 Confirm hide 함수
 * const { showAlert, hideAlert, showConfirm, hideConfirm } = useModal();
 */

const useModal = () => {
  const showAlert = ({
    isOpen = true,
    title = "알림",
    text = "",
    onClick = () => {},
    width = "calc(100% - 40px)",
    height = "240px",
  }) => commonStore.alert.setProps({ isOpen, title, text, onClick, width, height });

  const hideAlert = () => commonStore.alert.setProps(null);

  const showConfirm = ({
    isOpen = true,
    title = "알림",
    text = "",
    onClickY = () => {},
    onClickN = () => {},
    width = "calc(100% - 40px)",
    height = "240px",
  }) => {
    commonStore.confirm.setProps({
      isOpen,
      title,
      text,
      onClickY,
      onClickN,
      width,
      height,
    });
  };

  const hideConfirm = () => commonStore.confirm.setProps(null);

  return {
    showAlert,
    hideAlert,
    showConfirm,
    hideConfirm,
  };
};

export default useModal;

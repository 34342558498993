import dynamic from "next/dynamic";

/**
 * - Device Component Dynamic Import & Export
 * - path: :common/Device/index.js
 * @type {React.ComponentType<{}>}
 */
const Device = dynamic(() => import("/common/Device/Device"), { ssr: false });

export default Device;

import { pxFn, pxToRemConvertFn } from "/common/Fn";
import theme from "/styles/theme";

/**
 * - Flex Center Align Css
 * - path: /common/Const/Css
 * @type {{alignItems: string, display: string, justifyContent: string}}
 */
export const FLEX_CENTER_CSS = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

/**
 * - Width 100% Css
 * - path: /common/Const/Css
 * @type {{width: string}}
 */
export const W_100_CSS = {
  width: "100%",
};

/**
 * - height: 100% Css
 * - path: /common/Const/Css
 * @type {{height: string}}
 */
export const H_100_CSS = {
  height: "100%",
};

/**
 * - width & height 100% Css
 * - path: /common/Const/Css
 * @type {{width: string, height: string}}
 */
export const WH_100_CSS = {
  ...W_100_CSS,
  ...H_100_CSS,
};

/**
 * - 단어 축양형 Css
 * - path: /common/Const/Css
 * @type {{overflow: string, whiteSpace: string, textOverflow: string}}
 */
export const ELLIPSIS_CSS = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

/**
 * - Flex 상태에서 단어 축양형 Css
 * - path: /common/Const/Css
 * @type {{overflow: string, whiteSpace: string, flex: string, minWidth: number, textOverflow: string}}
 */
export const FLEX_ELLIPSIS_CSS = {
  minWidth: 0,
  flex: "1 1 auto",
  ...ELLIPSIS_CSS,
};

/**
 * - 상세 정보 상품 이름 및 가격 나타내는 공통 Css
 * - path: /common/Const/Css
 * @type {{border: string, padding: string, alignItems: string, borderRadius: string, display: string, fontSize: string, boxSizing: string, justifyContent: string}}
 */
export const DETAIL_INFO_PRODUCT_CSS = {
  padding: pxFn(24),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: pxFn(14),
  border: `${pxFn(1)} solid ${theme.palette.divider}`,
  borderRadius: pxFn(8),
  boxSizing: "border-box",
};

/**
 * - Table Option Css
 * - path: /common/Const/Css
 * @type {{backgroundColor: string, borderRadius: string, color: string, fontSize: string, marginLeft: string, height: string}}
 */
export const TABLE_OPTION_BUTTON_CSS = {
  marginLeft: pxFn(8),
  height: pxFn(40),
  fontSize: pxToRemConvertFn(14),
  borderRadius: pxFn(8),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.text.disabled,
};

/**@module Fn.js **/

import dayjs from "dayjs";
import { isEqual } from "lodash";
import { toast } from "react-toastify";

import { simpleApi } from "/api";
import {
  DELIVERY_PROCESS_STEP,
  NEAR_DISTANCE_DELIVERY_PROCESS_STEP,
  QUICK_PROCESS_STEP,
  RESERVATION_QUICK_PROCESS_STEP,
  SHIPPING_DELIVERY_STEP,
  SMALL_ALERT_WH,
} from "/common/Const/Object";
import { DEFAULT_TABLE_PAGE_SIZE, REGULAR_EXPRESSION } from "/common/Const/Rule";
import commonStore from "/mobx/store/commonStore";
import theme from "/styles/theme";

/**
 * - 기본 react table getCellProps 함수
 * @param clickRow - current click row data state
 * @param setClickRow - current click row setState
 */
export const getCellPropsBasic = (clickRow, setClickRow) => (d) => {
  const {
    row: { original },
  } = d;

  const onClick = () => {
    const isEqualClickRow = isEqual(clickRow, original);

    if (isEqualClickRow) {
      allCommonCloseFn();
    } else {
      setClickRow(original);
      pcDetailOpen();
    }
  };

  return { onClick };
};

/**
 * 공통 알람 show fn
 * @param isOpen
 * @param text
 * @param onClick
 * @param width
 * @param height
 */

export const showAlertFn = ({
  isOpen = true,
  text = "",
  onClick = () => {},
  width = "calc(100% - 20px)",
  height = "240px",
}) => commonStore.alert.setProps({ isOpen, text, onClick, width, height });

/**
 * mobx 공통 관리 요소 함수 모음
 */

export const mobileDetailInfoClose = () => commonStore.mobileDetailInfoToggle.close();
export const mobileToggleMenuClose = () => commonStore.mobileToggleMenu.close();

// export const showLoadingFn = () => commonStore.loading.open();
export const hideLoadingFn = () => commonStore.loading.close();
export const setLoadingSizeFn = (size = 85) => commonStore.loading.setLoadingSize(size);
export const setLoadingTextFn = (text = "") => commonStore.loading.setLoadingText(text);

export const hideAlertFn = () => commonStore.alert.setProps(null);
export const hideConfirmFn = () => commonStore.confirm.setProps(null);

export const kakaoPostCodeClearAction = () => commonStore?.kakaoPostCodePopUp.close();

export const pcClickRowClear = () => commonStore.pcClickRow?.setClickRow(null);

export const pcDetailOpen = () => commonStore.pcDetailInfoToggle.open();
export const pcDetailClose = () => commonStore.pcDetailInfoToggle.close();
export const pcDetailToggle = () => commonStore.pcDetailInfoToggle.toggle();
export const mobileTopOpen = () => commonStore.mobileTop.open();

/**
 * - 공통 중앙 관리 요소 close fn
 */
export const allCommonCloseFn = () => {
  kakaoPostCodeClearAction();

  pcClickRowClear();
  pcDetailClose();

  mobileDetailInfoClose();
  mobileToggleMenuClose();
  mobileTopOpen();

  hideLoadingFn();

  hideAlertFn();
  hideConfirmFn();
};

/**
 * * NumberFormat 라이브러리에서의 string 결과 값을 세자리 콤마를 없애고 숫자로 반환하는 함수
 * @param value {string}
 * @returns {number}
 */
export const makeNumber = (value) => Number(String(value)?.replaceAll(",", ""));

/**
 * *  string 으로 표현된 숫자를 3자리 콤마로 나타낸 string 으로 반환하는 함수
 * @param value {string}
 * @returns {string}
 */
export const makeNumberAndThousandsSeparator = (value) =>
  value ? makeNumber(value).toLocaleString("en") : value;

/**
 * @description 번호 변환 함수
 * @description 01012345678 => 010-1234-5678
 * @param str {string}
 * @returns {*}
 */

export const makePhoneNum = (str) => {
  let regx;
  const numbersOnlyStr = str?.replace(/\D/g, "") ?? str;

  if (numbersOnlyStr?.length === 10) {
    if (numbersOnlyStr.startsWith("02")) {
      regx = /^(\d{2})(\d{4})(\d{4})$/;
    } else {
      regx = REGULAR_EXPRESSION.PHONE_NUM;
    }
  } else if (numbersOnlyStr?.length === 12) {
    regx = REGULAR_EXPRESSION.SAFE_PHONE_NUM;
  } else {
    regx = REGULAR_EXPRESSION.PHONE_NUM;
  }

  return numbersOnlyStr?.replace(regx, "$1-$2-$3");
};

/**
 * @description 번호 변환 함수
 * @description 01012345678 => 010-1234-5678
 * @param str {string}
 * @returns {*}
 */

export const makeBusinessNum = (str) =>
  str.substring(0, 3) + "-" + str.substring(3, 5) + "-" + str.substring(5, 10);

/**
 * @description 기존 API 함수에서 data 객체 결과 값만 가져오는 함수
 * @description {header, body, ....}
 * @param url {string}
 * @param queryData {object}
 * @returns {*}
 */

export const getAPIData = async (url, queryData = {}) => {
  const { data } = await simpleApi.post(url, queryData);
  return data;
};

/**
 * api fetch 후 common OnSuccess Function
 * @param header{object}
 * @param successFn {Function}
 * @param onClick {Function}
 * @returns {*}
 */

export const commonOnSuccess = (header, successFn = () => {}, onClick = () => {}) => {
  if (header?.success) {
    return successFn();
  } else {
    setLoadingSizeFn();
    setLoadingTextFn();
    hideLoadingFn();
    return showAlertFn({
      text: header?.resMsg,
      ...SMALL_ALERT_WH,
      onClick,
    });
  }
};

export const onKeyDownEscCommonFn = (
  checkStateList = [],
  checkStateListFnList = [],
  additionalClearFn = allCommonCloseFn,
  // onKeyDownEscAdditionalFn = () => {},
) => {
  const checkAll = checkStateList?.some((check) => check);

  if (checkAll) {
    const onKeyDownEsc = () => {
      // onKeyDownEscAdditionalFn();
      checkStateListFnList?.map((fn) => {
        fn(false);
      });
      additionalClearFn();
    };

    const escKeyClose = (e) => (e.keyCode === 27 ? onKeyDownEsc() : null);
    window.addEventListener("keydown", escKeyClose);

    return () => window.removeEventListener("keydown", escKeyClose);
    // } else {
    //   additionalClearFn();
  }
};

export const mobileNotify = (text) =>
  toast(text, {
    toastId: "mobileNotify",
    autoClose: 700,
    position: "top-center",
    icon: false,
    closeButton: false,
    limit: 1,
    hideProgressBar: true,
    progress: undefined,
  });

export const enterKeyPreventDefault = (e) => {
  if (e.code === "Enter") e.preventDefault();
};

const numKor = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구", "십"];
const danKor = [
  "",
  "십",
  "백",
  "천",
  "",
  "십",
  "백",
  "천",
  "",
  "십",
  "백",
  "천",
  "",
  "십",
  "백",
  "천",
];

/**
 * price to Korean
 * @param val
 * @returns {string}
 */
export const priceToKorean = (val) => {
  val = String(val);

  let result = "";
  if (val && !isNaN(val)) {
    // CASE: 금액이 공란/NULL/문자가 포함된 경우가 아닌 경우에만 처리

    for (var i = 0; i < val.length; i++) {
      let str = "";
      let num = numKor[val.charAt(val.length - (i + 1))];
      if (num !== "") str += num + danKor[i]; // 숫자가 0인 경우 텍스트를 표현하지 않음
      switch (i) {
        case 4:
          str += "만";
          break; // 4자리인 경우 '만'을 붙여줌 ex) 10000 -> 일만
        case 8:
          str += "억";
          break; // 8자리인 경우 '억'을 붙여줌 ex) 100000000 -> 일억
        case 12:
          str += "조";
          break; // 12자리인 경우 '조'를 붙여줌 ex) 1000000000000 -> 일조
      }

      result = str + result;
    }

    // Step. 불필요 단위 제거
    if (result.indexOf("억만") > 0) result = result.replace("억만", "억");
    if (result.indexOf("조만") > 0) result = result.replace("조만", "조");
    if (result.indexOf("조억") > 0) result = result.replace("조억", "조");
    if (result.startsWith("일")) result = result.substring(1);

    result = result + "원";
  }

  return result;
};

export const onKeyUpInput = (ref, setState) => {
  let searchQuery = ref.current.value;
  setTimeout(() => {
    if (searchQuery === ref?.current?.value) {
      setState(searchQuery);
    }
  }, 250);
};

/**
 * px to rem
 * @param num
 */
export const pxToRemConvertFn = (num) => `${0.0625 * num}rem`;

export const pxFn = (num) => `${num}px`;

/**
 * double onsubmit prevent fn
 * @param timer
 * @param setTimer
 * @param fn
 * @param delay
 */

export const debounceCallback = ({ timer, setTimer, fn, delay }) => {
  if (timer) {
    clearTimeout(timer);
  }

  const newTimer = setTimeout(fn, delay);
  setTimer(newTimer);
};

export const onScroll = (ref, scrollToggle) => {
  const handleScroll = () => scrollToggle(ref.current.scrollTop >= 50);

  ref.current?.addEventListener("scroll", handleScroll);
  return () => ref.current?.removeEventListener("scroll", handleScroll);
};

export const calDeliveryStep = (data) => {
  const processMstDTO = data?.processOrderDTO?.processMstDTO || {};
  const processTypeCd = processMstDTO?.processTypeCd || "";
  const processStatsCd = processMstDTO?.processStatsCd || "";

  const isQuick = ["NQ"].some((code) => processTypeCd === code);
  const isReservationQuick = ["BQ"].some((code) => processTypeCd === code);
  const isNearDistanceDelivery = ["SD"].some((code) => processTypeCd === code);

  const deliveryOrderInfoDTO = data?.deliveryOrderDetailDTO?.deliveryOrderInfoDTO || {};
  const isDeliveryDone = deliveryOrderInfoDTO?.deliveryStatsCd?.split("LV")[1] >= 6;

  if (isQuick) {
    // 즉시 퀵
    return QUICK_PROCESS_STEP[processStatsCd];
  } else if (isReservationQuick) {
    // 예약 퀵
    return RESERVATION_QUICK_PROCESS_STEP[processStatsCd];
  } else if (isNearDistanceDelivery) {
    // 근거리 배송
    return NEAR_DISTANCE_DELIVERY_PROCESS_STEP[processStatsCd];
  } else {
    // 택배 / 당일배송 / 새벽 배송
    return isDeliveryDone ? 4 : DELIVERY_PROCESS_STEP[processStatsCd];
  }
};

export const calShippingDeliveryStep = (data) => {
  const deliveryOrderInfoDTO = data?.deliveryOrderDetailDTO?.deliveryOrderInfoDTO || {};

  return SHIPPING_DELIVERY_STEP[deliveryOrderInfoDTO?.deliveryStatsCd];
};

export const deliveryBtnPropsFn = (data, showDeliveryCancelConfirmAlertToggle) => {
  const processMstDTO = data?.processOrderDTO?.processMstDTO || {};
  const isQuick = ["NQ", "BQ"].some((code) => processMstDTO?.processTypeCd === code);

  const isCanCancelDelivery = ["DO0010", "DO0015", "DO0020", "DO0030", "DO0031", "DO0032"].some(
    (cd) => cd === processMstDTO?.processStatsCd,
  );

  const isCompleteDelivery = processMstDTO?.processStatsCd === "DO0040";

  const styles = {
    fontSize: pxToRemConvertFn(14),
    color: theme.palette.primary.main,
    border: `${pxFn(1)} solid ${theme.palette.primary.main}`,
  };

  if (isCompleteDelivery) {
    return {
      text: "배송 완료",
      styles,
    };
  } else if (isCanCancelDelivery) {
    return {
      text: "배송 취소",
      styles,
      handleClick: () => showDeliveryCancelConfirmAlertToggle(true),
    };
  } else {
    return {
      text: isQuick ? "출고완료" : "배송 중",
      styles,
    };
  }
};

export const getWeekOriginNumber = (date = dayjs()) => {
  // 해당 날짜 (일)
  const currentDate = date.date();

  // 이번 달 1일로 지정
  const startOfMonth = date.set("date", 1);

  // 이번 달 1일이 무슨 요일인지 확인
  const weekDay = startOfMonth.day(); // 0: Sun ~ 6: Sat

  // ((요일 - 1) + 해당 날짜) / 7일로 나누기 = N 주차
  return parseInt((weekDay - 1 + currentDate) / 7) + 1;
};

export const getYearNumber = (date = dayjs()) => {
  const month = date.month();
  const year = date.year();

  const weekOriginNumber = getWeekOriginNumber(date);

  if (month === 0 && weekOriginNumber === 1) {
    const startOfMonth = date.set("date", 1);
    const startWeekDay = startOfMonth.day(); // 0: Sun ~ 6: Sat

    if (startWeekDay !== 1) {
      if (startWeekDay > 1) {
        return year - 1;
      } else {
        const dateWeekDay = date.day();

        if (dateWeekDay === 0) {
          return year - 1;
        }
      }
    }
  }

  return year;
};

export const getMonthNumber = (date = dayjs()) => {
  const month = date.month();

  const weekOriginNumber = getWeekOriginNumber(date);

  if (weekOriginNumber === 1) {
    const startOfMonth = date.set("date", 1);
    const startWeekDay = startOfMonth.day(); // 0: Sun ~ 6: Sat

    if (startWeekDay !== 1) {
      const calMonth = (month + 12) % 12;

      if (startWeekDay > 1) {
        return calMonth === 0 ? 1 : calMonth;
      } else {
        const dateWeekDay = date.day();

        if (dateWeekDay === 0) {
          return calMonth === 0 ? 12 : calMonth;
        }
      }
    }
  }

  return month + 1;
};

/**
 * @description 이번달의 몇주차인주 구하는 함수
 * @param date
 * @returns {number}
 */

export const getWeekNumber = (date = dayjs()) => {
  // 해당 날짜 (일)
  const currentDate = date.date();
  const currentDateWeekDay = date.day();

  // 이번 달 1일로 지정
  const startOfMonth = date.set("date", 1);

  // // 이번 달 1일이 무슨 요일인지 확인
  const weekDay = startOfMonth.day(); // 0: Sun ~ 6: Sat
  const weekNum = parseInt((weekDay - 1 + currentDate) / 7) + 1;

  if (weekNum !== 1 && currentDateWeekDay === 0) {
    return weekNum - 1;
  } else if (weekNum === 1 && (weekDay > 1 || (weekDay === 0 && currentDateWeekDay === 0))) {
    const lastMonth = date.subtract(1, "month");

    const lastMonthStartOfMonth = lastMonth.startOf("month");

    const lastMonthWeekDay = lastMonthStartOfMonth.day(); // 0: Sun ~ 6: Sat
    const lastMonthEndOfMonth = lastMonth.endOf("month").date();

    return (
      parseInt((lastMonthWeekDay - 1 + lastMonthEndOfMonth) / 7) + (lastMonthWeekDay === 1 ? 1 : 0)
    );
  } else {
    return weekNum;
  }
};

export const getEndWeekNumber = (date = dayjs()) => {
  const endOfMonth = date.endOf("month");
  const endOfMonthWeek = getWeekOriginNumber(endOfMonth);

  const startOfMonth = date.startOf("month");
  const startOfMonthWeekDay = startOfMonth.day(); // 0: Sun ~ 6: Sat

  if (startOfMonthWeekDay > 1) {
    return endOfMonthWeek - 1;
  } else {
    return endOfMonthWeek;
  }
};

export const getWeekStartDate = (format = "YY.MM.DD", date = dayjs()) => {
  const TODAY = date;
  const TODAY_MONTH = TODAY.month() + 1;
  const MONTH = getMonthNumber(TODAY);

  return TODAY_MONTH === MONTH
    ? TODAY.startOf("week").add(1, "day").format(format)
    : TODAY.subtract(1, "week").startOf("week").add(1, "day").format(format);
};

// export const isGeneralWeek = (date = dayjs()) => {
//   // 해당 날짜 (일)
//   const currentDate = date.date();
//   const currentDateWeekDay = date.day();
//
//   // 이번 달 1일로 지정
//   const startOfMonth = date.set("date", 1);
//
//   // // 이번 달 1일이 무슨 요일인지 확인
//   const weekDay = startOfMonth.day(); // 0: Sun ~ 6: Sat
//   const weekNum = parseInt((weekDay - 1 + currentDate) / 7) + 1;
//
//   return weekNum === 1 && (weekDay > 1 || (weekDay === 0 && currentDateWeekDay === 0));
// };

/**
 * * React Table 검색에서 table size || page 가 변경될때 위의 함수를 통해 queryData 상태 값을 바꾸기 위한 함수
 * @example    useEffect(() => paginationFn(queryData, setQueryData, page, size), [page, size]);
 * @description useQuery 에 query key 로 queryData 를 설정하면 queryData 가 변경됨에 따라 자동으로 다시 요청이 보내집니다.
 * @description 이를 통해 page, size 값이 변경될 때 위의 함수를 실행시켜서 다시 검색 요청이 자동으로 보내지게 합니다.
 * @param queryData {object} - query data state
 * @param setQueryData {fn} - query data setState
 * @param page {number} - page state
 * @param size {number} - size state
 */
export const paginationFn = (queryData, setQueryData, page, size = DEFAULT_TABLE_PAGE_SIZE) => {
  if (queryData) {
    setQueryData((prev) => ({
      ...prev,
      page,
      size,
    }));
  }
};

/**
 * * 파일 확장자명 추출 함수
 * @param filename
 * @returns {string}
 */
export const getExtensionOfFilename = (filename) => {
  let _fileLen = filename.length;

  /**
   * lastIndexOf('.')
   * 뒤에서부터 '.'의 위치를 찾기위한 함수
   * 검색 문자의 위치를 반환한다.
   * 파일 이름에 '.'이 포함되는 경우가 있기 때문에 lastIndexOf() 사용
   */
  let _lastDot = filename.lastIndexOf(".");

  // 확장자 명만 추출한 후 소문자로 변경
  return filename.substring(_lastDot, _fileLen).toLowerCase();
};

export const calListSumFn = (list, ...res) => {
  const temp = (list || [])?.reduce(
    (acc, cur) =>
      acc +
      parseInt(
        res?.length === 1
          ? cur[res[0]]
          : res?.length === 2
          ? cur[res[0]][res[1]]
          : res?.length === 3
          ? cur[res[0]][res[1]][res[2]]
          : 0,
      ),

    0,
  );
  return temp || 0;
};

export const commonOnKeyDown =
  (fnList = [], checkTrueList = [], checkFalseList = []) =>
  (e) => {
    if (
      checkTrueList?.every((state) => state) &&
      checkFalseList?.every((state) => !state) &&
      e?.key === "Escape"
    ) {
      fnList?.map((l) => l(false));
      e?.stopImmediatePropagation();
    }
  };

export const pcDetailOnKeyDown =
  (fnList = [], checkFalseList = [], checkTrueList = []) =>
  (e) => {
    if (
      commonStore.pcDetailInfoToggle.isOpen &&
      checkTrueList?.every((state) => state) &&
      checkFalseList?.every((state) => !state) &&
      e?.key === "Escape"
    ) {
      commonStore.pcDetailInfoToggle.close();
      fnList?.map((l) => l(false));
    }
  };

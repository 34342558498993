import { styled } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";

import { W_100_CSS } from "/common/Const/Css";
import { pxFn } from "/common/Fn";

export const ToastCustomContainer = styled(ToastContainer)(({ theme }) => {
  return {
    padding: 0,
    ...W_100_CSS,

    "& .Toastify__toast": {
      fontSize: pxFn(14),
      borderRadius: pxFn(24),
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
      background: theme.palette.primary.main,
    },
  };
});

import { Button, useTheme } from "@material-ui/core";

/**
 * - main color contrast button
 * - path: /components/common/Button/SubButton
 * @param text - btn text
 * @param width - btn width
 * @param height - btn height
 * @param margin - btn margin
 * @param fontSize -btn fontSize
 * @param type : btn type
 * @param disabled - btn disable
 * @param handleClick - btn click fn
 * @param styles - btn style
 * @param restProps
 * @returns {JSX.Element}
 *
 */
export const PrimarySubBtn = ({
  text,
  width = "160px",
  height = "52px",
  margin = "0",
  fontSize = "16px",
  type = "button",
  disabled = false,
  handleClick = () => {},
  styles = {},
  ...restProps
}) => {
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      type={type}
      style={{
        width,
        height,
        color: disabled ? theme.palette.grey[600] : theme.palette.primary.main,
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.background.paper
            : theme.palette.text.primary,
        borderColor: disabled ? theme.palette.grey[600] : theme.palette.primary.main,
        borderRadius: "8px",
        fontSize: fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        minWidth: "20px",
        padding: "5px",
        margin: margin,
        ...styles,
      }}
      onClick={handleClick}
      data-cy={restProps?.dataCy || "primarySubBtn"}
      {...restProps}>
      {text}
    </Button>
  );
};

export const IconBtn = ({
  text,
  disabled = false,
  width = "160px",
  height = "52px",
  icon,
  type = "button",
  handleClick = () => {},
  styles = {},
  ...restProps
}) => {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      type={type}
      disabled={disabled}
      style={{
        width: width,
        height: height,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderRadius: "8px",
        ...styles,
      }}
      startIcon={icon ? icon : ""}
      onClick={handleClick}
      data-cy={restProps?.dataCy || "iconBtn"}>
      {text}
    </Button>
  );
};

import { Button, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Image from "next/image";
import { MdRefresh } from "react-icons/md";

import { WH_100_CSS } from "/common/Const/Css";
import { pxFn, pxToRemConvertFn } from "/common/Fn";
import { commonImagePropsFn } from "/common/Image";

/**
 * - main color button
 * - path: /components/common/Button/MainButton
 * @param type - button type
 * @param text - button text
 * @param width - button width
 * @param height - button height
 * @param margin - button margin
 * @param fontSize - button fontSize
 * @param handleClick - button click fn
 * @param styles - button styles
 * @param disabled - button disable state
 * @param restProps
 * @returns {JSX.Element}
 * @example
 * <MainColorButton
 *     text={"등록"}
 *     width={"100%"}
 *     handleClick={()=> console.log('example')}
 * />
 */
export const MainColorButton = ({
  type = "button",
  text,
  width = pxFn(160),
  height = pxFn(48),
  margin = 0,
  fontSize = pxToRemConvertFn(16),
  handleClick = () => {},
  styles = {},
  disabled = false,
  ...restProps
}) => {
  const theme = useTheme();

  return (
    <Button
      type={type}
      style={{
        margin,
        minWidth: pxFn(20),
        width,
        height,
        backgroundColor: disabled
          ? theme.palette.action.disabledBackground
          : theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: fontSize,
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: pxFn(8),
        ...styles,
      }}
      onClick={handleClick}
      disabled={disabled}
      data-cy={restProps?.dataCy || "mainColorBtn"}
      {...restProps}>
      {text}
    </Button>
  );
};

export const MainLineButton = ({
  type = "button",
  text,
  width = pxFn(160),
  height = pxFn(52),
  margin = 0,
  fontSize = pxToRemConvertFn(16),
  handleClick = () => {},
  styles = {},
  disabled = false,
  ...restProps
}) => {
  const theme = useTheme();
  return (
    <Button
      type={type}
      style={{
        margin,
        minWidth: pxFn(20),
        width,
        height,
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.background.paper
            : theme.palette.text.primary,
        color:
          theme.palette.mode === "light" ? theme.palette.text.secondary : theme.palette.grey[600],
        fontSize,
        border: `1px solid ${theme.palette.text.secondary}`,
        borderRadius: pxFn(8),
        ...styles,
      }}
      onClick={handleClick}
      disabled={disabled}
      data-cy={"mainLineBtn"}
      {...restProps}>
      {text}
    </Button>
  );
};

/**
 * - Main Gradient Button Component
 * - path: /components/common/Button/MainButton
 * @param text - button text
 * @param onClick - button onClick fn
 * @param disabled - button disable state
 * @param restProps - extra button props
 * @returns {JSX.Element}
 *
 */
export const MainGradientButton = ({ text, onClick, disabled = false, ...restProps }) => {
  const classes = useStyles({ disabled });

  return (
    <Button
      type={restProps?.type || "button"}
      className={classes.gradientNewBtn}
      variant={restProps?.variant || "outlined"}
      startIcon={
        restProps?.startIcon || (
          <Image
            alt={""}
            {...commonImagePropsFn.add(16, 16)}
          />
        )
      }
      onClick={onClick}
      disabled={disabled}
      data-cy={restProps?.dataCy || "mainGradientButton"}
      {...restProps}>
      {text}
    </Button>
  );
};

/**
 * - common reset button
 * - mostly used in table filter
 * @param onClick - click fn
 * @param restProps
 * @returns {JSX.Element}
 *
 */
export const MainResetButton = ({ onClick, ...restProps }) => {
  const classes = useStyles({ ...restProps });

  return (
    <Button
      className={classes.resetBtn}
      onClick={onClick}>
      <MdRefresh size={pxFn(20)} />
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  resetBtn: (props) => ({
    // marginLeft: pxFn(8),
    height: pxFn(36),
    color: theme.palette.text.secondary,
    fontSize: pxToRemConvertFn(14),
    borderRadius: pxFn(8),
    backgroundColor: theme.palette.text.disabled,
    ...props,
    "&:hover": {
      background: theme.palette.text.disabled,
    },
  }),
  gradientNewBtn: ({ disabled }) => ({
    padding: `${pxFn(6)} ${pxFn(20)}`,
    ...WH_100_CSS,
    color: theme.palette.common.white,
    fontSize: pxToRemConvertFn(14),
    fontWeight: theme.typography.fontWeightBold,
    backgroundImage: disabled
      ? "none"
      : "linear-gradient(to left, #b056ff 0%, #7055ff 51% , #b056ff 100%)",
    backgroundColor: disabled ? theme.palette.text.disabled : "none",
    backgroundSize: "200% auto",
    border: "none",
    borderRadius: pxFn(8),
    transition: "0.5s",
    cursor: "pointer",
    // ...props,

    "&:hover": {
      backgroundPosition: "right center",
    },
    "& span": {
      // paddingRight: `${pxFn(5)} !important`,
    },
  }),
}));

import { makeStyles } from "@material-ui/core/styles";

import { FLEX_CENTER_CSS, TABLE_OPTION_BUTTON_CSS, W_100_CSS, WH_100_CSS } from "/common/Const/Css";
import { pxFn, pxToRemConvertFn } from "/common/Fn";

/**
 * - Common makeStyles 모음
 * - path: /common/MakeStyle.js
 */
export const useDefaultStyles = makeStyles((theme) => {
  return {
    "@keyframes action": {
      "0%": {
        width: "0px ",
      },
      "100%": {
        width: "16px",
      },
    },
    tableContainer: ({ titleHeight }) => {
      return {
        position: "relative",
        paddingBottom: `${pxFn(6)}`,
        ...WH_100_CSS,
        "& .tableContents": {
          ...W_100_CSS,
          height: `calc(100% - ${pxFn(titleHeight || 0)})`,
          overflowY: "scroll",

          "& table thead tr th": {
            padding: 0,
            height: pxFn(40),
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 600],
            borderRight: 0,
            fontSize: pxToRemConvertFn(14),
            textAlign: "center",
          },

          "& table tbody tr td": {
            height: pxFn(40),
            color: theme.palette.text.primary,
            fontSize: pxToRemConvertFn(14),
            textAlign: "center",

            "& div": {
              margin: `0 0 0 ${pxFn(8)}`,
              textAlign: "left",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },

            "&:last-child": {
              margin: 0,
            },
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      };
    },
    flexTableContainer: ({ height }) => {
      return {
        position: "relative",
        ...WH_100_CSS,

        "& .tableContents": {
          ...W_100_CSS,
          height,
          backgroundColor: theme.palette.background.paper,
          overflowY: "scroll",

          "& table thead tr th": {
            height: pxFn(40),
            ...FLEX_CENTER_CSS,

            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.text.disabled,
            fontSize: pxToRemConvertFn(14),
            textAlign: "center",
          },

          "& table tbody tr td": {
            height: pxFn(40),
            display: "flex",
            alignItems: "center",
            fontSize: pxToRemConvertFn(14),
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,

            "& div": {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.text.disabled,
          },
        },
      };
    },
    tableFilterBtn: () => {
      const isDarkMode = theme.palette.mode === "dark";
      return {
        ...TABLE_OPTION_BUTTON_CSS,
        color: theme.palette.text.secondary,
        border: `${pxFn(1)} solid ${theme.palette.divider}`,
        backgroundColor: isDarkMode ? theme.palette.text.disabled : theme.palette.background.paper,
      };
    },

    f: {
      display: "flex",
    },
    fJCc: {
      display: "flex",
      justifyContent: "center",
    },
    fJCsb: {
      display: "flex",
      justifyContent: "space-between",
    },
    fJCfe: {
      display: "flex",
      justifyContent: "flex-end",
    },
    fAIc: {
      display: "flex",
      alignItems: "center",
    },
    fAIfe: {
      display: "flex",
      alignItems: "flex-end",
    },
    fJCcAIc: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fJCsbAIc: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    fJCsbAIfe: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    fJCfeAIc: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    fFDc: {
      display: "flex",
      flexDirection: "column",
    },
    fFDcJCsb: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    fFDcAIc: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    fFDcJCcAIc: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },

    w: {
      width: "100%",
    },
    h: {
      height: "100%",
    },
    wh: {
      width: "100%",
      height: "100%",
    },
    textField: {
      width: (props) => (props.width ? props.width : pxFn(320)),
      height: (props) => (props.height ? props.height : pxFn(48)),
      "& .MuiInputBase-root": {
        width: (props) => (props.width ? props.width : pxFn(320)),
        height: (props) => (props.height ? props.height : pxFn(48)),
        backgroundColor: theme.palette.background.paper,
        borderRadius: pxFn(8),
      },
      "& input": {
        fontSize: (props) => (props.isFold ? pxToRemConvertFn(13) : pxToRemConvertFn(14)),
        "&::placeholder": {
          color: theme.palette.text.tertiary,
          opacity: 1,
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: pxFn(1),
        borderColor: theme.palette.divider,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.divider,
      },
      "@media (hover: none) ": {
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.divider,
        },
      },
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.divider,
      },

      "& .errorText": {
        "& p": {
          fontSize: pxToRemConvertFn(14),
          color: theme.palette.error.main,
        },
      },
      "& .Mui-focused .errorText": {
        display: "none",
      },
    },
    emptySearch: {
      marginTop: pxFn(80),
      textAlign: "center",
      "& > div": {
        marginTop: pxFn(16),
        fontSize: (props) => (props.isFold ? pxToRemConvertFn(14) : pxToRemConvertFn(16)),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    detailInfoBox: {
      width: "560px",
      height: "100vh",
      boxShadow: "0 0 3rem 0 rgba(0,0,0,0.2)",
      backgroundColor: theme.palette.common.white,
      zIndex: 1,

      "& > .detailInfoHead": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "6rem",
        padding: "0 2.5rem",
        fontSize: "1.25rem",
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        borderBottom: "1px solid #f5f5f5",
        boxShadow: (props) => (props.scroll ? "0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.05)" : ""),
      },

      "& .detailInfoContent": {
        overflowY: "scroll",
        height: (props) =>
          props?.clickRow?.processOrderDTO?.processMstDTO?.processStatsCd === "DO0990" ||
          props?.clickRow?.deliveryStatsStep === "99"
            ? "calc(100% - 140px)"
            : "calc(100% - 192px)",

        "& .showStepBar": {
          backgroundColor: "#f5f5f5",
          padding: "31px 40px",
          height: (props) => (props?.isQuick ? "136px" : "156px"),

          "& .stepList": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 2rem",
          },

          "& .stepBarBox": {
            backgroundColor: "#dddddd",
            height: "0.5rem",
            borderRadius: "0.5rem",
            marginTop: "1rem",

            "& .stepBar": {
              width: 0,
              height: "100%",
              backgroundImage: "linear-gradient(to left, #b056ff, #7055ff)",
              transition: "width 700ms",
              transitionDelay: "400ms",
              borderRadius: "8px",
            },
          },
        },

        "& .deliveryStep": {
          width: "480px",
          height: "56px",
          backgroundColor: "#dddddd",
          borderRadius: "8px",
          margin: "-30px auto 0",
          padding: "0 27px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          "& .step": {
            fontSize: "15px",
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightMedium,

            "&.active": {
              color: theme.palette.common.black,
            },
          },
        },

        "& .detailInfo": {
          padding: "32px 40px 0px",
          borderBottom: `1px solid ${theme.palette.text.disabled}`,

          "& .InfoTitle": {
            color: theme.palette.text.secondary,
            fontSize: "0.875rem",
            marginBottom: "0.75rem",
          },

          "& .Information": {
            color: theme.palette.text.primary,
            fontSize: "1.25rem",
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: 1.5,
            marginBottom: "1.5rem",
          },
        },
      },
    },
  };
});

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import Image from "next/image";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useEvent, useWindowSize } from "react-use";

import { commonOnKeyDown, pxFn, pxToRemConvertFn } from "/common/Fn";
import { commonImagePropsFn } from "/common/Image";
import { useDefaultStyles } from "/common/MakeStyle";
import useKakaoPostCodePopUp from "/hooks/useKakaoPostCodePopUp";

const KakaoPostCodePopUp = () => {
  const { isKakaoPostCodeOpen, kakaoPostCodeProps, kakaoPostCodeClose } = useKakaoPostCodePopUp();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const classes = useStyles({ windowWidth, windowHeight });
  const defaultClasses = useDefaultStyles();

  useEvent("keydown", commonOnKeyDown([kakaoPostCodeClose], [isKakaoPostCodeOpen]));

  if (!isKakaoPostCodeOpen) return <></>;

  return (
    <Box
      className={classes.bg}
      // onClick={kakaoPostCodeClose}
    >
      <Box className={classes.container}>
        <Box
          className={classes.title}
          onClick={(e) => e.stopPropagation()}>
          <Box>{kakaoPostCodeProps?.title || "주소검색"}</Box>
          <Box className={defaultClasses.fAIc}>
            <Image
              alt={""}
              {...commonImagePropsFn.close(18, 18)}
              onClick={kakaoPostCodeClose}
            />
          </Box>
        </Box>
        <DaumPostcodeEmbed
          style={{
            width: pxFn(400),
            height: pxFn(468),
          }}
          {...{
            ...kakaoPostCodeProps,
            scriptUrl: "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
          }}
        />
      </Box>
    </Box>
  );
};

export default observer(KakaoPostCodePopUp);

const useStyles = makeStyles((theme) => {
  return {
    bg: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.7)",
      zIndex: theme.zIndex.infinite * theme.zIndex.infinite - 1,
    },
    container: ({ windowWidth, windowHeight }) => ({
      position: "fixed",
      left: windowWidth / 2 - 400 / 2,
      top: windowHeight / 2 - 468 / 2,
      backgroundColor: theme.palette.background.paper,
      border: `${pxFn(2)} solid ${theme.palette.divider}`,
      // border: `${pxFn(1)} solid ${theme.palette.divider}`,
      borderRadius: pxFn(4),
      zIndex: theme.zIndex.infinite * theme.zIndex.infinite,
      fontSize: pxToRemConvertFn(16),
      fontWeight: theme.typography.fontWeightMedium,
    }),
    title: {
      position: "relative",
      padding: `${pxFn(12)}`,
      height: pxFn(48),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
      borderBottom: `${pxFn(2)} solid ${theme.palette.divider}`,
      color: theme.palette.text.primary,
      zIndex: theme.zIndex.infinite * theme.zIndex.infinite,
    },
  };
});

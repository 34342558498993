var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3pgYUoTqwG2pcJ0vDGmsw"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://cc1aec7bf77f49c9a3f54b12a72fbaa1@o4505004132990976.ingest.sentry.io/4505004134432768",
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== "development",
});

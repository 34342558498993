import commonStore from "/mobx/store/commonStore";

/**
 * - 카카오 주소 검색 커스텀 팝업 관리를 위한 custom hook
 * - 현재 PC 에서 만 사용중, Mobile 에서는 직접 창 뛰워서 사용하여 이것 사용하지 않음
 * - mobx store 의 commonStore.kakaoPostCodePopUp 를 제어하기 위한 custom hook
 * @example
 * // isKakaoPostCodeOpen: 카카오 주소 검색 커스텀 모달 상태를 나타내는 변수
 * // kakaoPostCodeProps: 카카오 주소 검색 커스텀 모달 option 을 위한 변수 (ex): title 등)
 * // kakaoPostCodeOpen: 카카오 주소 검색 커스텀 모달 열기 위한 함수
 * // kakaoPostCodeClose: 카카오 주소 검색 커스텀 모달 닫기 위한 함수
 * // kakaoPostCodeToggle: 카카오 주소 검색 커스텀 모달 토글 위한 함수
 * const { isKakaoPostCodeOpen, kakaoPostCodeProps, kakaoPostCodeOpen, kakaoPostCodeClose, kakaoPostCodeToggle } = useKakaoPostCodePopUp();
 */
const useKakaoPostCodePopUp = () => {
  const isKakaoPostCodeOpen = commonStore.kakaoPostCodePopUp?.isOpen;
  const kakaoPostCodeProps = commonStore.kakaoPostCodePopUp?.props;

  const kakaoPostCodeOpen = (props) => commonStore.kakaoPostCodePopUp?.open(props);
  const kakaoPostCodeClose = (props) => commonStore.kakaoPostCodePopUp?.close(props);
  const kakaoPostCodeToggle = (props) => commonStore.kakaoPostCodePopUp?.toggle(props);

  return {
    isKakaoPostCodeOpen,
    kakaoPostCodeProps,
    kakaoPostCodeOpen,
    kakaoPostCodeClose,
    kakaoPostCodeToggle,
  };
};

export default useKakaoPostCodePopUp;

import { observable } from "mobx";

/**
 * 클라이언트 내부 공통으로 사용된는 mobx store 입니다.
 */

const commonStore = observable({
  // kakaoPostCodePopUp: 카카오 주소 검색 커스텀 모달 상태를 관리하는 mobx store 변수
  kakaoPostCodePopUp: {
    isOpen: false,
    props: null,
    open(props) {
      this.isOpen = true;
      this.props = props;
    },
    close() {
      this.isOpen = false;
      this.props = null;
    },
    toggle(props = null) {
      return this.isOpen ? this.open(props) : this.close(props);
    },
  },
  // pcClickRow: UI 클릭 후 저장해야 할 데이터 공통으로 저장 및 관리하기 위한 mobx store 변수
  pcClickRow: {
    clickRow: null,
    setClickRow(value) {
      this.clickRow = value;
    },
  },
  // pcDetailInfoToggle: PC 오른쪽 슲라이드 detail 모달 상태를 관리하기 위한 mobx store 변수
  pcDetailInfoToggle: {
    isOpen: false,
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle(value) {
      this.isOpen = value ? value : !this.isOpen;
    },
  },
  // mobileDetailInfoToggle: Mobile detail 모달 상태를 관리하기 위한 mobx store 변수
  mobileDetailInfoToggle: {
    isOpen: false,
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  // mobileToggleMenu: Mobile Hamburger Toggle Menu 상태를 관리하기 위한 mobx store 변수 (현재 모바일 화면에 햄버거 메뉴가 없을 수 있음)
  mobileToggleMenu: {
    isOpen: false,
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  // mobileTop: Mobile 화면 윗 부분 상태 관리를 위한 mobx store 변수 (ex): Mobile QR Scan 시 false 설정 등)
  mobileTop: {
    isOpen: true,
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  // mobileBottomMenu: Mobile 화면 아랫 부분 상태 관리를 위한 mobx store 변수 (ex): Mobile QR Scan 시 false 설정 등)
  mobileBottomMenu: {
    isOpen: true,
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  // loading: 화면 중앙 전체 로딩을 관리하기 위한 mobx store 변수
  loading: {
    isOpen: false,
    size: 85,
    text: "",
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    setLoadingSize(size) {
      this.size = size;
    },
    setLoadingText(text) {
      this.text = text;
    },
  },
  //  alert: 공통 알람을 나타내기 위한 mobx store 변수
  alert: {
    props: null,
    setProps(props) {
      this.props = props;
    },
  },
  // confirm: 공통 confirm 창을 나타내기 위한 mobx store 변수
  confirm: {
    props: null,
    setProps(props) {
      this.props = props;
    },
  },
});

export default commonStore;

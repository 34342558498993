import axios from "axios";

const getBaseURL = (name) => {
  if (name === "") {
    return `/api/${process.env.ENV_DEV_API_VERSION}/`;
  } else {
    return `/api/${name}/${process.env.ENV_DEV_API_VERSION}/`;
  }
};

export const genAxiosInstance = (name) => {
  return axios.create({
    baseURL: getBaseURL(name),
    headers: { "Content-Type": "application/json;charset=UTF-8" },
    withCredentials: true,
  });
};
